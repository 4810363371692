<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.usuarios-sistema.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.usuarios-sistema.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.usuarios-sistema.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex flex-row-reverse align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon dark left>
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-select
                  :items="filters.data.roles"
                  item-text="name"
                  item-value="id"
                  :label="$t('app.roles.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  v-model="filters.selected.roles"
                  outlined
                  rounded
                  dense
                >
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  v-model="filters.selected.query"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="queryClean"
            :custom-filter="customFilter"
          >
            <template v-slot:item.roles="{ item }">
              <template v-if="item.roles.length > 0">
                <v-chip
                  v-for="(role, index) in item.roles"
                  label
                  small
                  :key="`role-${index}`"
                >
                  {{ role.name }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.sucursales="{ item }">
              <template v-if="item.sucursales.length > 0">
                <v-chip
                  v-for="(sucursal, index) in item.sucursales"
                  label
                  small
                  :key="`sucursal-${index}`"
                >
                  {{ sucursal.nombre }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.avatar="{ item }">
              <v-col class="d-flex justify-center">
                <v-img
                  :src="item.avatar"
                  :lazy-src="preloader"
                  width="60"
                />
              </v-col>
            </template>
            <template v-slot:item.direccion="{ item }">
              {{
                [
                  item.persona.calle,
                  item.persona.colonia,
                  item.persona.localidad.nombre,
                  item.persona.municipio.nombre,
                  item.persona.estado.nombre
                ].join(', ')
              }}
            </template>
            <template v-slot:item.telefonos="{ item }">
              {{ item.persona.telefonos ? item.persona.telefonos.join(', ') : '' }}
            </template>
            <template v-slot:item.fecha_nacimiento="{ item }">
              {{ $moment(item.persona.fecha_nacimiento).format('MMM Do, YYYY') }}
              <br>
              <small>{{ $moment(item.persona.fecha_nacimiento).fromNow(true) }}</small>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <!--<v-menu bottom left >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="item.promotes.includes('referee') && item.promotes.includes('player')"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="!item.promotes.includes('referee')"
                      @click="openPromoteToReferee(item)"
                    >
                      <v-list-item-title>
                        {{ $t('app.buttons.promote_to') }}
                        {{ $t('app.arbitros.single')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!item.promotes.includes('player')"
                      @click="openPromoteToPlayer(item)"
                    >
                      <v-list-item-title>
                        {{ $t('app.buttons.promote_to') }}
                        {{ $t('app.jugadores.single') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>-->

                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <usuarios-add />
    <usuarios-edit />
    <usuarios-detail />
    <usuarios-delete />

    <jugadores-add />
    <jugadores-add-to-team-question />
    <jugadores-add-to-team />
    <jugadores-add-to-team-edit />
    <arbitros-add />
  </v-container>
</template>

<script>
import latinize from 'latinize'
import customFilter from "../../../../plugins/customFilter";

    export default {
      name: "Usuarios",
      mixins: [customFilter],
      components: {
        UsuariosAdd: () => import('./UsuariosAdd'),
        UsuariosEdit: () => import('./UsuariosEdit'),
        UsuariosDetail: () => import('./UsuariosDetail'),
        UsuariosDelete: () => import('./UsuariosDelete'),

        JugadoresAdd: () => import('../jugadores/JugadoresAdd'),
        ArbitrosAdd: () => import('../arbitros/ArbitrosAdd'),
        JugadoresAddToTeamQuestion: () => import('../jugadores/JugadoresAddToTeamQuestion'),
        JugadoresAddToTeam: () => import('../jugadores/JugadoresAddToTeam'),
        JugadoresAddToTeamEdit: () => import('../jugadores/JugadoresAddToTeamEdit')
      },
      data () {
        return {
          headers: [
            {
              text: this.$t('app.headers.id'),
              value: 'id',
            },
            {
              text: this.$t('app.roles.name'),
              value: 'roles'
            },
            {
              text: this.$t('app.sucursales.name'),
              value: 'sucursales'
            },
            {
              text: this.$t('app.headers.name'),
              value: 'persona.fullname',
            },
            {
              text: this.$t('app.headers.email'),
              value: 'email',
            },
            {
              text: this.$t('app.headers.borndate'),
              value: 'fecha_nacimiento',
              sortable: false
            },
            {
              text: '',
              value: 'actions',
              sortable: false
            }
          ],
          items: [],
          filters: {
            data: {
              roles: []
            },
            selected: {
              roles: [],
              query: ''
            }
          }
        }
      },
      computed: {
        filteredItems () {
          if (this.filters.selected.roles.length > 0) {
            return this.items.filter(item => {
              let roles = item.roles.filter(item => this.filters.selected.roles.includes(item.id))
              console.log(roles)
              return roles.length > 0
            })
          } else {
            return this.items
          }
        },
        queryClean () {
          return latinize(this.filters.selected.query)
        }
      },
      async mounted() {
        await this.init()

        EventBus.$on('reload-items', () => {
          this.init()
        })
      },
      methods: {
        async init () {
          this.toggleLoader()

          await this.$http.get(route('v1/system-user'))
          .then(response => {
            if (response.body.code === 200 && response.body.data.length > 0) {
              this.items = response.body.data.map(user => {
                if (user && user.roles && user.roles.length > 0) {
                  user.promotes = user.roles.map(role => role.name)
                } else {
                  user.promotes = []
                }
                return user
              })
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

          this.filters.data.roles = await this.getRolesProtected()

          this.toggleLoader()
        },
        openItemAdd () {
          EventBus.$emit('usuarios-add')
        },
        openItemEdit (item) {
          EventBus.$emit('usuarios-edit', item)
        },
        openItemDetail (item) {
          EventBus.$emit('usuarios-detail', item)
        },
        openItemDelete (item) {
          EventBus.$emit('usuarios-delete', item)
        },
        openPromoteToPlayer (user) {
          EventBus.$emit('jugadores-add', user)
        },
        openPromoteToReferee (user) {
          EventBus.$emit('arbitros-add', user)
        }
      }
    }
</script>

<style scoped>

</style>
